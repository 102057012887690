<div class="toolbar" *ngIf="!hidden" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div [hidden]="!showBackButton" (click)="backButtonClick()">
      <mat-icon class="toolbar__back-icon" svgIcon="back-button-icon"></mat-icon>
    </div>

    <button *ngIf="showSelectDeselectAll" (click)="selectAllButtonClick()" mat-button color="primary">
      Select All
    </button>

    <button *ngIf="showSelectDeselectAll" (click)="deselectAllButtonClick()" mat-button color="primary">
      Deselect All
    </button>

    <div class="toolbar__title" fxLayout="row" fxLayoutAlign="start center">
      <div>{{ title }}</div>
    </div>
  </div>

  <div class="toolbar__right-section" fxLayout="row" fxLayoutAlign="end center">
    <button
      *ngIf="actionButton1Label && actionButton1IsMain && actionButton1Show"
      (click)="button1Click()"
      mat-button
      color="primary"
    >
      {{ actionButton1Label }}
    </button>
    <button
      *ngIf="actionButton2Label && actionButton2IsMain && actionButton2Show"
      (click)="button2Click()"
      mat-button
      color="primary"
    >
      {{ actionButton2Label }}
    </button>
    <button
      *ngIf="actionButton3Label && actionButton3IsMain && actionButton3Show"
      (click)="button3Click()"
      mat-button
      color="primary"
    >
      {{ actionButton3Label }}
    </button>
    <button
      *ngIf="actionButton4Label && actionButton4IsMain && actionButton4Show"
      (click)="button4Click()"
      mat-button
      color="primary"
    >
      {{ actionButton4Label }}
    </button>
    <button
      *ngIf="actionButton5Label && actionButton5IsMain && actionButton5Show"
      (click)="button5Click()"
      mat-button
      color="primary"
    >
      {{ actionButton5Label }}
    </button>
    <button
      *ngIf="actionButton6Label && actionButton6IsMain && actionButton6Show"
      (click)="button6Click()"
      mat-button
      color="primary"
    >
      {{ actionButton6Label }}
    </button>

    <div
      *ngIf="
        (actionButton1Label && actionButton1Show && !actionButton1IsMain) ||
        (actionButton2Label && actionButton2Show && !actionButton2IsMain) ||
        (actionButton3Label && actionButton3Show && !actionButton3IsMain) ||
        (actionButton4Label && actionButton4Show && !actionButton4IsMain) ||
        (actionButton5Label && actionButton5Show && !actionButton5IsMain) ||
        (actionButton6Label && actionButton6Show && !actionButton6IsMain)
      "
    >
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="action-button-icon"></mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button *ngIf="actionButton1Label && !actionButton1IsMain" (click)="button1Click()" mat-menu-item>
          {{ actionButton1Label }}
        </button>
        <button *ngIf="actionButton2Label && !actionButton2IsMain" (click)="button2Click()" mat-menu-item>
          {{ actionButton2Label }}
        </button>
        <button *ngIf="actionButton3Label && !actionButton3IsMain" (click)="button3Click()" mat-menu-item>
          {{ actionButton3Label }}
        </button>
        <button *ngIf="actionButton4Label && !actionButton4IsMain" (click)="button4Click()" mat-menu-item>
          {{ actionButton4Label }}
        </button>
        <button *ngIf="actionButton5Label && !actionButton5IsMain" (click)="button5Click()" mat-menu-item>
          {{ actionButton5Label }}
        </button>
        <button *ngIf="actionButton6Label && !actionButton6IsMain" (click)="button6Click()" mat-menu-item>
          {{ actionButton6Label }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
