<!--final results-->
<mat-card class="mat-elevation-z notes-container">
  <mat-card-header>
    <mat-card-title>Notes</mat-card-title>
  </mat-card-header>
  <mat-card-content id="contentFinalResults">
    <div class="item">
      <div class="error-message" *ngIf="isAtLeastOneSectionEmpty">
        Enter at least one line of dimensions in all sections to continue
      </div>

      <div class="error-message" *ngIf="!isAtLeastOneSectionEmpty && isShipmentWeightEmpty && isFormSubmitted">
        Enter Weight to continue
      </div>
      <div
        class="item680notes"
        id="item680Comments"
        *ngIf="this.item680Validations.getItem680Comments() && isFormSubmitted"
      >
        {{ item680Comments }}
      </div>
    </div>
    <br />
    <div class="button-bar-container">
      <div class="item copy-notes-button-container">
        <button mat-button [disabled]="disableCopyCommentsButton()" (click)="copyComments()">
          Copy notes to clipboard
        </button>
      </div>
      <div *ngIf="displayButton()" class="item copy-notes-button-container">
        <button mat-button [disabled]="disabledCopyNoteButton" (click)="copyNoteToInspection()">
          Copy notes to inspection
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
