export class CollectionUtils {
  // This method can get the group to whom the value belongs. It receives the
  // collection and the value to look for. Collections needs to have this
  // structure: [ "Group_Name": [lower_endpoint, upper_endpoint ] ]
  // For instance: [ "Group_1": [0, 10], "Group_2": [10, 20] ]
  // It will check that value is gte lower_endpoint, and lt upper_endpoint
  public static getGroupBasedOnValue(collection: any, value: number): string {
    let response = '';

    Object.entries(collection).forEach(([group, interval]) => {
      if (value >= interval[0] && value < interval[1]) {
        response = group;
        return;
      }
    });

    return response;
  }
}
