import {
  Directive,
  OnInit,
  OnDestroy,
  Output,
  ElementRef,
  EventEmitter
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  private listening: boolean;
  private subscription = new Subscription();

  @Output() appClickOutside: EventEmitter<any>;

  constructor(private _elRef: ElementRef) {
    this.listening = false;
    this.appClickOutside = new EventEmitter<any>();
  }

  ngOnInit() {
    this.subscription.add(
      fromEvent(document, 'click')
        .pipe(
          delay(1),
          tap(() => {
            this.listening = true;
          })
        )
        .subscribe((event: MouseEvent) => {
          this.onGlobalClick(event);
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onGlobalClick(event: MouseEvent) {
    if (event instanceof MouseEvent && this.listening === true) {
      if (this.isDescendant(this._elRef.nativeElement, event.target) === true) {
        this.appClickOutside.emit({
          target: event.target || null,
          value: false
        });
      } else {
        this.appClickOutside.emit({
          target: event.target || null,
          value: true
        });
      }
    }
  }

  isDescendant(parent, child) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
}
