import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PieceDimensions } from '@xpo-ltl/sdk-inspections';

@Component({
  selector: 'app-dimension-line',
  templateUrl: './dimension-line.component.html',
  styleUrls: ['./dimension-line.component.scss']
})
export class DimensionLineComponent {
  constructor() {}

  @Input()
  inspectionPieceDimension: PieceDimensions;

  @Input()
  dimensionError: string;

  @Output()
  change = new EventEmitter<boolean>();

  public dimensionsChanged() {
    this.change.emit(true);
  }
}
