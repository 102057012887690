<div class="unread-broadcast-message-dialog__header">
  <h2 mat-dialog-title>{{ title }}</h2>
  <button
    *ngIf="isAcknowledgeLaterButtonDisplayed"
    class="unread-broadcast-message-dialog__header-button"
    (click)="onAcknowledgeLaterClicked()"
    mat-flat-button
    xpoButton
    color="warn"
  >
    Acknowledge later
  </button>
</div>
<div mat-dialog-content>
  <div *ngIf="!isAcknowledgeLaterButtonDisplayed; else display_error_message">
    <p class="unread-broadcast-message-dialog__help-text">
      Please read and acknowledge each message to access Inspection application.
    </p>
  </div>
  <mat-divider></mat-divider>
  <div class="unread-broadcast-message-dialog__list-container">
    <div class="unread-broadcast-message-dialog__list-item" *ngFor="let unreadMessage of unreadMessages">
      <app-unread-broadcast-message-item
        [isAcknowledgeLaterButtonDisplayed]="isAcknowledgeLaterButtonDisplayed"
        [unreadMessage]="unreadMessage"
        [employeeId]="employeeId"
        (responseMessage)="onResponseMessage($event)"
      >
      </app-unread-broadcast-message-item>
      <mat-divider></mat-divider>
    </div>

    <div *ngIf="!isProduction" class="unread-broadcast-message-dialog__forTest">
      Only for test environment
      <div class="unread-broadcast-message-dialog__forTest-toggle">
        <mat-slide-toggle (change)="onRemoveItemInstanceIdChanged($event)">Remove Item Instance ID </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

<ng-template #display_error_message>
  <p class="unread-broadcast-message-dialog__help-text">
    Error occurred while getting unread messages. Please read all the messages and click acknowledge later button to
    enter the app.
  </p>
</ng-template>
