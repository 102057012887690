<h1 mat-dialog-title class="status-change__title">Warning</h1>

<mat-dialog class="status-change__content">
  <mat-dialog-content>
    <div *ngFor="let key of changeStateErrorDataKeys">
      <div style="margin-bottom: 30px">
        {{ key }}
        <div *ngFor="let value of changeStateErrorData.errorMap[key]">
          <div style="margin-left: 50px">{{ value }}</div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</mat-dialog>
