import { Dimension2D } from './dimension2d';

export class Dimension3D extends Dimension2D {
  public height: number;

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): void {
    this.height = height;
  }

  public getVolume(): number {
    let volume = 0;

    if (this.pieces && this.length && this.width && this.height) {
      volume = this.pieces * this.length * this.width * this.height;
    }

    return volume;
  }

  public getVolumeFromInchesToFeet(): number {
    // 1728 is the convertion factor to cubic feet from inches
    return this.getVolume() / 1728;
  }
}
