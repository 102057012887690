<div>
  <h2 md-dialog-title>{{ title }}</h2>
  <mat-dialog-content>
    <div [innerHTML]="message"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-container">
    <button mat-button color="primary" (click)="sendAction('append')">Append</button>
    <button mat-button color="primary" (click)="sendAction('replace')">Replace</button>
    <button mat-button color="secondary" (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>
