import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Dimension2D } from '../../../../classes/dimension2d';
import { Item680ValidationsService } from '../services/item680-validations.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pallet-dimensions',
  templateUrl: './pallet-dimensions.component.html',
  styleUrls: ['./pallet-dimensions.component.scss']
})
export class PalletDimensionsComponent implements OnInit, OnDestroy {
  private eventsSubscription: any;

  @Input() events: Observable<string>;

  public palletErrors: Array<string> = new Array<string>();

  constructor(public item680Validations: Item680ValidationsService) {}

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe($event => {
      if ($event === 'submit') {
        this.item680Validations.getPalletSurfaces().forEach((item, index) => {
          this.validatePalletDimensions(item, index);
        });
      } else if ($event === 'reset') {
        this.item680Validations.getPalletSurfaces().push(new Dimension2D());
        this.palletErrors = new Array<string>();
      }
    });

    if (!this.item680Validations.getPalletSurfaces().length) {
      this.item680Validations.getPalletSurfaces().push(new Dimension2D());
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  public addSurface(): void {
    const palletSurfaces = this.item680Validations.getPalletSurfaces();
    const palletSurface = palletSurfaces[palletSurfaces.length - 1];
    if (palletSurface.getWidth() > 0 || palletSurface.getLength() > 0 || palletSurface.getPieces() > 0) {
      palletSurfaces.push(new Dimension2D());
    }
  }

  public handlePalletChange(): void {
    this.addSurface();
    this.item680Validations.resetComments();
    this.item680Validations.calculateShipmentProperties();
  }

  public validatePalletDimensions(palletSurface: Dimension2D, index: number): void {
    let palletError = '';

    const length = palletSurface.getLength();
    const width = palletSurface.getWidth();

    if (!this.item680Validations.isValidDimension(+length) || !this.item680Validations.isValidDimension(+width)) {
      palletError = 'All dimensions must be > 0 and in .5 increments.';
    }

    // Don't add the error if row is empty
    if (length || width || palletSurface.getPieces()) {
      this.palletErrors[index] = palletError;

      // If there is an error, reset calculations
      if (palletError) {
        this.item680Validations.resetComments();
      }
    } else {
      this.palletErrors[index] = '';
    }
  }
}
