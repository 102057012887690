<mat-dialog-actions>
  <div class="content-left">
    <h2 mat-dialog-title>{{ title }}</h2>
  </div>
  <div class="content-right">
    <button mat-button (click)="clear()" *ngIf="!sendInformation">Clear Form</button>
  </div>
</mat-dialog-actions>
<br />
<mat-divider></mat-divider>
<mat-dialog-content>
  <div *ngIf="!sendInformation">
    <app-recommended-rules-summary></app-recommended-rules-summary>
    <app-recommended-rules-form [isNewRule]="isNewRule"></app-recommended-rules-form>
    <app-recommended-rules-filters></app-recommended-rules-filters>
  </div>

  <div *ngIf="sendInformation">
    <br />
    <br />
    <app-recommended-rules-summary></app-recommended-rules-summary>
    <div class="warning">
      <p>Are you sure you want to {{ action | dialogActionPipe }} this Recommendation Rule?</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="content-left">
    <button
      mat-flat-button
      color="warn"
      (click)="deleteRule()"
      *ngIf="!sendInformation && action === DialogAction.EDIT"
    >
      Delete
    </button>
  </div>
  <div class="content-right">
    <button mat-button (click)="close()" *ngIf="!sendInformation">Cancel</button>
    <button mat-button (click)="backFormScreen()" *ngIf="sendInformation">No</button>
    <button
      mat-button
      color="primary"
      (click)="confirmDialog()"
      [disabled]="!validForm() || !validFilters()"
      *ngIf="!sendInformation"
    >
      Save
    </button>
    <button mat-button color="primary" (click)="performRecommendationRuleOperation()" *ngIf="sendInformation">
      Yes
    </button>
  </div>
</mat-dialog-actions>
