export class IFrameDisplayData {
  title: string;
  url: string;
  errorMessage: string;

  constructor(title: string, url: string, errorMessage: string) {
    this.title = title;
    this.url = url;
    this.errorMessage = errorMessage;
  }
}
