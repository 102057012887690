import { Component, OnInit } from '@angular/core';
import { RecommendedRulesService } from '../services/recommended-rules.service';
import { RuleFilter } from '../classes/rule-filter';

@Component({
  selector: 'app-recommended-rules-filters',
  templateUrl: './recommended-rules-filters.component.html',
  styleUrls: ['./recommended-rules-filters.component.scss']
})
export class RecommendedRulesFiltersComponent implements OnInit {
  constructor(private recommendedRulesService: RecommendedRulesService) {}

  ngOnInit() {}

  public canAddFilter(): boolean {
    const isFormValid = this.recommendedRulesService.isFormValid();
    const canAddRuleFilter = this.recommendedRulesService.canAddRuleFilter();

    return isFormValid && canAddRuleFilter;
  }

  public addNewFilter(): void {
    if (this.recommendedRulesService.canAddRuleFilter()) {
      this.addFilter();
    }
  }

  private addFilter(): void {
    if (this.recommendedRulesService.isLastFilterAvailable()) {
      this.recommendedRulesService.addLastFilterAvailable();
    } else {
      // Add filter to the rule
      this.recommendedRulesService.addRuleFilter(new RuleFilter());
    }
  }

  public getRuleFilters(): Array<RuleFilter> {
    return this.recommendedRulesService.getRecommendationRuleFilters();
  }
}
