<div class="unread-broadcast-message-item">
  <p class="unread-broadcast-message-item__message-from">From: {{ unreadMessage?.createdBy }}</p>
  <p class="unread-broadcast-message-item__message-id">ID: {{ unreadMessage?.messageId }}</p>
  <p class="unread-broadcast-message-item__message-text">{{ unreadMessage?.message }}</p>
  <div class="unread-broadcast-message-item__response">
    <button
      *ngIf="!isAcknowledgeLaterButtonDisplayed"
      mat-button
      color="primary"
      (click)="onAcknowledgeResponseMessage()"
    >
      Acknowledge
    </button>
  </div>
</div>
