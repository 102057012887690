<!--overall dimensions-->
<mat-card class="mat-elevation-z" id="overallDim">
  <mat-card-header>
    <mat-card-title>Overall Dimensions</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div id="contShipmentWeight">
      <mat-form-field appearance="legacy">
        <mat-label>Shipment Weight</mat-label>
        <input
          matInput
          placeholder="Shipment Weight"
          (change)="handleDimensionChange()"
          [ngModel]="this.item680Validations.getShipmentWeight()"
          (ngModelChange)="this.item680Validations.setShipmentWeight($event)"
          inputNumber
          [acceptDecimalPlaces]="true"
          [decimalPlaces]="2"
          appNumberFormat
          inputmode="numeric"
          pattern="[0-9\-]*"
        />
      </mat-form-field>
    </div>
    <mat-list>
      <mat-list-item *ngFor="let dimension of getOverallDimensions(); let i = index">
        <div id="inputContentOveDim">
          <mat-form-field appearance="legacy">
            <mat-label>Pieces</mat-label>
            <input
              matInput
              placeholder="Pieces"
              (change)="handleDimensionChange()"
              [ngModel]="dimension.getPieces()"
              (ngModelChange)="dimension.setPieces($event)"
              maxlength="4"
              inputNumber
              [acceptDecimalPlaces]="false"
              appNumberFormat
              inputmode="numeric"
              pattern="[0-9\-]*"
            />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>Length</mat-label>
            <input
              matInput
              placeholder="Length"
              (change)="handleDimensionChange()"
              [ngModel]="dimension.getLength()"
              (ngModelChange)="dimension.setLength($event)"
              maxlength="5"
              inputNumber
              [acceptDecimalPlaces]="true"
              [decimalPlaces]="1"
              appNumberFormat
              inputmode="numeric"
              pattern="[0-9\-].+"
            />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>Width</mat-label>
            <input
              matInput
              placeholder="Width"
              (change)="handleDimensionChange()"
              [ngModel]="dimension.getWidth()"
              (ngModelChange)="dimension.setWidth($event)"
              maxlength="5"
              inputNumber
              [acceptDecimalPlaces]="true"
              [decimalPlaces]="1"
              appNumberFormat
              inputmode="numeric"
              pattern="[0-9\-].+"
            />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>Height</mat-label>
            <input
              matInput
              placeholder="Height"
              (change)="handleDimensionChange()"
              [ngModel]="dimension.getHeight()"
              (ngModelChange)="dimension.setHeight($event)"
              maxlength="5"
              inputNumber
              [acceptDecimalPlaces]="true"
              [decimalPlaces]="1"
              appNumberFormat
              inputmode="numeric"
              pattern="[0-9\-].+"
            />
          </mat-form-field>
        </div>
        <div *ngIf="dimensionErrors[i]" class="mat-error">{{ dimensionErrors[i] }}</div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
