<!--commodity surface area-->
<mat-card class="mat-elevation-z">
  <mat-card-header>
    <mat-card-title>Commodity Dimensions</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="this.item680Validations.getCommoditySurfaces().length > 0">
      <mat-list>
        <mat-list-item *ngFor="let surface of this.item680Validations.getCommoditySurfaces(); let i = index">
          <div class="form-surface">
            <mat-form-field>
              <input
                matInput
                placeholder="Pieces"
                (change)="handleCommodityChange()"
                [(ngModel)]="surface.pieces"
                inputNumber
                [acceptDecimalPlaces]="false"
                appNumberFormat
                inputmode="numeric"
                pattern="[0-9\-]*"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Length"
                (change)="handleCommodityChange()"
                [(ngModel)]="surface.length"
                inputNumber
                [acceptDecimalPlaces]="true"
                [decimalPlaces]="1"
                appNumberFormat
                inputmode="numeric"
                pattern="[0-9\-].+"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Width"
                (change)="handleCommodityChange()"
                [(ngModel)]="surface.width"
                inputNumber
                [acceptDecimalPlaces]="true"
                [decimalPlaces]="1"
                appNumberFormat
                inputmode="numeric"
                pattern="[0-9\-].+"
              />
            </mat-form-field>
          </div>
          <div *ngIf="commodityErrors[i]" class="mat-error">{{ commodityErrors[i] }}</div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
  <mat-card-actions> </mat-card-actions>
</mat-card>
