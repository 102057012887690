import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ConfirmCancelDataInterface } from '../../interfaces/confirm-cancel-data';

@Component({
  selector: 'app-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.scss']
})
export class ConfirmCancelComponent implements OnInit {
  title: string;
  content: SafeHtml;
  leftButtonTitle: string;
  rightButtonTitle: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmCancelComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmCancelDataInterface,
    private sanitizer: DomSanitizer
  ) {
    this.title = data.heading;
    this.content = sanitizer.bypassSecurityTrustHtml(data.body);
    this.leftButtonTitle = data.leftButtonTitle;
    this.rightButtonTitle = data.rightButtonTitle;
  }

  ngOnInit() {}
}
