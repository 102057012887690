import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNoPipe'
})
export class YesNoPipe implements PipeTransform {
  transform(value: any, defaultValue: any): string {
    const trueMatches = ['TRUE', 'T', 'YES', 'Y'];
    const falseMatches = ['FALSE', 'F', 'NO', 'N'];

    const valueAsString = `${value}`.toUpperCase();
    if (trueMatches.indexOf(valueAsString) >= 0) {
      return 'Yes';
    }
    if (falseMatches.indexOf(valueAsString) >= 0) {
      return 'No';
    }

    return defaultValue;
  }
}
