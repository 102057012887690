import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

import * as _ from 'lodash';
import { GetInspectionShipmentDetailsResp, PieceDimensions } from '@xpo-ltl/sdk-inspections';

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionsComponent implements OnChanges {
  constructor() {}

  @Input()
  public shipmentDetails: GetInspectionShipmentDetailsResp;

  public dimensions: PieceDimensions[];

  ngOnChanges() {
    this.dimensions = new Array<PieceDimensions>();
    if (
      this.shipmentDetails &&
      this.shipmentDetails.shipmentDetails &&
      this.shipmentDetails.shipmentDetails.inspectorPieceDimensions
    ) {
      this.dimensions = <PieceDimensions[]>(
        _.sortBy(this.shipmentDetails.shipmentDetails.inspectorPieceDimensions, d => d.seqNbr)
      );
    }
  }
}
