import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { BroadcastMessage } from '../../classes/broadcast-messages/broadcast-message';
import { BroadcastMessageDialogLengths } from '../../enums/form-lengths/broadcast-message-dialog-lengths.enum';
import { BroadcastMessageDialogFormNames } from '../../enums/form-names/broadcast-message-dialog-form-names.enum';

export class BroadcastMessageDialogFormBuilder {
  /**
   * Create a new form group
   */
  static create(fb: UntypedFormBuilder) {
    return fb.group({
      [BroadcastMessageDialogFormNames.MESSAGE]: fb.control('', [
        Validators.required,
        Validators.minLength(BroadcastMessageDialogLengths.MESSAGE_MIN_LENGTH),
        Validators.maxLength(BroadcastMessageDialogLengths.MESSAGE_MAX_LENGTH)
      ]),
      [BroadcastMessageDialogFormNames.EFFECTIVE_DATE]: fb.control(new Date(), [Validators.required]),
      [BroadcastMessageDialogFormNames.EXPIRY_DATE]: fb.control('')
    });
  }

  /**
   * Update the values form from a BroadcastMessage
   */
  static setValue(formGroup: UntypedFormGroup, broadcastMessage: BroadcastMessage) {
    formGroup.setValue({
      [BroadcastMessageDialogFormNames.MESSAGE]: broadcastMessage.message,
      [BroadcastMessageDialogFormNames.EFFECTIVE_DATE]: broadcastMessage.effectiveDate,
      [BroadcastMessageDialogFormNames.EXPIRY_DATE]: broadcastMessage.expirationDate
    });
  }

  /**
   * Create a BroadcastMessage object from the form
   * - message
   * - effectiveDate
   * - expirationDate
   */
  static getValue(formGroup: UntypedFormGroup): BroadcastMessage {
    const broadcastMessage = new BroadcastMessage();
    const formValues = formGroup.value;
    broadcastMessage.message = formValues[BroadcastMessageDialogFormNames.MESSAGE];
    broadcastMessage.effectiveDate = formValues[BroadcastMessageDialogFormNames.EFFECTIVE_DATE];
    broadcastMessage.expirationDate = formValues[BroadcastMessageDialogFormNames.EXPIRY_DATE];
    return broadcastMessage;
  }
}
