import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { UpdateInspectionStatusResp } from '@xpo-ltl/sdk-inspections';
import { Observable } from 'rxjs';
import { ChangeStatusErrorData } from '../../classes/change-status-error-data';
import { InjectedDialogComponent } from '../../interfaces/injected-dialog-component.interface';

@Component({
  selector: 'app-status-change-dialog',
  templateUrl: './status-change-dialog.component.html',
  styleUrls: ['./status-change-dialog.component.scss']
})
export class StatusChangeDialogComponent implements InjectedDialogComponent {
  public dataObservable: Observable<any>;

  public updateInspectionStatusResp: UpdateInspectionStatusResp;
  public message: string;
  public changeStateErrorData: ChangeStatusErrorData;
  public changeStateErrorDataKeys: string[];

  constructor(
    private dialogRef: MatDialogRef<StatusChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.updateInspectionStatusResp = data.updateInspectionStatusResp;
    this.changeStateErrorData = data.changeStateErrorData;
    this.changeStateErrorDataKeys = Object.keys(this.changeStateErrorData.errorMap);
  }
}
