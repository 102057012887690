export enum PartyRole {
  Shipper = 'SHIPPER',
  Consignee = 'CONSIGNEE',
  BillTo = 'BILL_TO'
}

export function toPartyRoleDisplayName(partyRole: PartyRole, capitalize: boolean = false): string {
  switch (partyRole) {
    case PartyRole.Shipper:
      return capitalize ? 'Shipper' : 'shipper';
    case PartyRole.Consignee:
      return capitalize ? 'Consignee' : 'consignee';
    case PartyRole.BillTo:
      return capitalize ? 'Bill To' : 'bill to';
  }
  return '';
}
