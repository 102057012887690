import { IndicatorName } from '../enums/indicator-name.enum';

export class Indicator {
  name: string;
  iconName: string;

  constructor(indicatorName: string) {
    this.name = indicatorName;
    this.iconName = this.getIconName(this.name);
  }

  private getIconName(name: string) {
    switch (name) {
      case IndicatorName.GUARANTEED:
        return 'ss_guaranteed';
      case IndicatorName.HAZMAT:
        return 'ss_hazmat';
      case IndicatorName.FREEZABLE:
        return 'ss_freezable';
      case IndicatorName.RAPID_REMOTE_SERVICE:
        return 'ss_rapid_remote_service';
      case IndicatorName.HEADLOAD:
        return 'ss_headload';
      case IndicatorName.FOOD:
        return 'ss_food';
      default:
        return '';
    }
  }
}
