import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Indicator } from '../../../../classes/indicator';
import { IndicatorName } from '../../../../enums/indicator-name.enum';

@Component({
  selector: 'app-pickup-request-mark-cell-renderer',
  templateUrl: './pickup-request-mark-cell-renderer.component.html',
  styleUrls: ['./pickup-request-mark-cell-renderer.component.scss'],
  host: { class: 'pickup-request-mark-cell-renderer' },
  encapsulation: ViewEncapsulation.None
})
export class PickupRequestMarkCellRendererComponent implements ICellRendererAngularComp {
  indicators: Indicator[] = [];
  indicatorName = IndicatorName;

  agInit(params: any): void {
    this.indicators = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.indicators = params.value;
    return true;
  }
}
