<script type="text/javascript">
  $('#iFrameDisplay').on('load', function() {
    $('.iFrameWrapper').removeClass('loading-2to5-gif');
    var document = $('#iFrameDisplay')[0].contentDocument;
    if (
      $(document)
        .find('body')
        .children().length == 0
    ) {
      $('#error_message').show();
      $('#iframeDisplay').hide();
    }
  });
</script>

<mat-dialog class="iFrameWrapper">
  <mat-dialog-content class="iFrameWrapper loading-2to5-gif">
    <iframe id="iFrameDisplay" [src]="iFrameContentSrc"></iframe>
    <div
      style="display: none;width:100%"
      class="text-error text-center"
      id="error_message"
    >
      {{ iFrameErrorMessage }}
    </div>
  </mat-dialog-content>
</mat-dialog>
