import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Indicator } from '../../../classes/indicator';
import { IndicatorName } from '../../../enums/indicator-name.enum';

@Component({
  selector: 'app-indicator-renderer',
  templateUrl: './indicator-renderer.component.html',
  styleUrls: ['./indicator-renderer.component.scss'],
  host: { class: 'indicator-renderer' },
  encapsulation: ViewEncapsulation.None
})
export class IndicatorRendererComponent implements ICellRendererAngularComp {
  indicators: Indicator[];
  indicatorName = IndicatorName;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.indicators = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.indicators = params.value;
    return true;
  }
}
