import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Item680ValidationsService } from '../services/item680-validations.service';
import { Observable } from 'rxjs';
import { Dimension3D } from '../../../../classes/dimension3d';

@Component({
  selector: 'app-overall-dimensions',
  templateUrl: './overall-dimensions.component.html',
  styleUrls: ['./overall-dimensions.component.scss']
})
export class OverallDimensionsComponent implements OnInit, OnDestroy {
  public dimensionErrors: Array<string> = new Array<string>();

  private eventsSubscription: any;

  @Input() events: Observable<string>;

  constructor(public item680Validations: Item680ValidationsService) {}

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe($event => {
      if ($event === 'submit') {
        this.item680Validations.getOverallDimensions().forEach((item, index) => {
          this.validateDimensions(item, index);
        });
      } else if ($event === 'reset') {
        this.item680Validations.getOverallDimensions().push(new Dimension3D());
        this.dimensionErrors = new Array<string>();
      }
    });

    if (!this.item680Validations.getOverallDimensions().length) {
      this.item680Validations.getOverallDimensions().push(new Dimension3D());
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  public addDimension(): void {
    const dimensions = this.item680Validations.getOverallDimensions();
    const dimension = dimensions[dimensions.length - 1];
    if (
      dimension.getWidth() > 0 ||
      dimension.getLength() > 0 ||
      dimension.getPieces() > 0 ||
      dimension.getHeight() > 0
    ) {
      dimensions.push(new Dimension3D());
    }
  }

  public handleDimensionChange(): void {
    this.addDimension();
    this.item680Validations.resetComments();
    this.item680Validations.calculateShipmentProperties();
  }

  public getOverallDimensions(): Array<Dimension3D> {
    return this.item680Validations.getOverallDimensions();
  }

  public validateDimensions(dimension: Dimension3D, index: number): void {
    let dimensionError = '';

    const length = dimension.getLength();
    const width = dimension.getWidth();
    const height = dimension.getHeight();

    if (
      !this.item680Validations.isValidDimension(+length) ||
      !this.item680Validations.isValidDimension(+width) ||
      !this.item680Validations.isValidDimension(+height)
    ) {
      dimensionError = 'All dimensions must be > 0 and in .5 increments.';
    }

    // Don't add the error if row is empty
    if (length || width || height || dimension.getPieces()) {
      this.dimensionErrors[index] = dimensionError;

      // If there is an error, reset calculations
      if (dimensionError) {
        this.item680Validations.resetComments();
      }
    } else {
      this.dimensionErrors[index] = '';
    }
  }
}
