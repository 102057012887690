import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberFormatDirective } from '../directives/numberformat.directive';

@NgModule({
  declarations: [NumberFormatDirective],
  exports: [NumberFormatDirective],
  imports: [
    CommonModule
  ]
})
export class InputValidationModule { }
