<div class="dimensions">
  <div class="dimensions__table">
    <table>
      <th></th>
      <th class="label">Pieces</th>
      <th></th>
      <th class="label">Length</th>
      <th></th>
      <th class="label">Width</th>
      <th></th>
      <th class="label">Height</th>
      <th></th>
      <th class="label">UOM</th>
      <tr *ngFor="let dimension of dimensions; let i = index">
        <td class="label">{{ dimension?.seqNbr }}.</td>
        <td class="value">{{ dimension?.pieceCnt }}</td>
        <td class="label">@</td>
        <td class="value">{{ dimension?.dimensions?.length }}</td>
        <td class="label">x</td>
        <td class="value">{{ dimension?.dimensions?.width }}</td>
        <td class="label">x</td>
        <td class="value">{{ dimension?.dimensions?.height }}</td>
        <td></td>
        <td class="value">{{ dimension?.dimensions?.dimensionsUom }}</td>
      </tr>
    </table>
  </div>
</div>
