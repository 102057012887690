<div class="broadcast-message-dialog">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div mat-dialog-content>
    <ng-container *ngIf="!sendInformation">
      <div [formGroup]="messageForm">
        <!-- NOTE: Effective date and Expiry date is not supported for beacon backend yet -->
        <!-- <div class="broadcast-message-dialog__date-range">
          <mat-form-field class="broadcast-message-dialog__effective-date">
            <input
              matInput
              [matDatepicker]="effectivePicker"
              placeholder="Effective Date"
              nextEnabled
              [formControlName]="BroadcastMessageDialogFormNames.EFFECTIVE_DATE"
            />
            <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectivePicker (closed)="onEffectiveDateClosed()"></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="broadcast-message-dialog__expiry-date">
            <input
              matInput
              [matDatepicker]="expiryPicker"
              [min]="messageForm.get(BroadcastMessageDialogFormNames.EFFECTIVE_DATE)?.value"
              placeholder="Expiry Date"
              [formControlName]="BroadcastMessageDialogFormNames.EXPIRY_DATE"
            />
            <mat-datepicker-toggle matSuffix [for]="expiryPicker"></mat-datepicker-toggle>
            <mat-datepicker #expiryPicker [opened]="expiryDateIsOpen" (closed)="onExpiryDateClosed()"></mat-datepicker>
          </mat-form-field>
        </div> -->
        <mat-form-field class="broadcast-message-dialog__message">
          <textarea
            matInput
            matInput
            [formControlName]="BroadcastMessageDialogFormNames.MESSAGE"
            placeholder="Message"
            rows="5"
          >
          </textarea>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="sendInformation">
      <div class="warning">
        <p>Are you sure you want to {{ action | dialogActionPipe }} this Broadcast Message?</p>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions class="broadcast-message-dialog__actions">
    <ng-container *ngIf="!sendInformation">
      <div class="content-left">
        <button
          mat-flat-button
          color="warn"
          (click)="onDeleteBroadcastMessage()"
          *ngIf="action === DialogAction.EDIT && !isReadOnly"
        >
          Delete
        </button>
      </div>
      <div class="content-right">
        <button mat-button (click)="close()" *ngIf="isReadOnly; else showCancel">Close</button>
        <button
          mat-button
          color="primary"
          (click)="onBroadcastMessage()"
          *ngIf="action === DialogAction.EDIT && !isReadOnly"
        >
          Broadcast
        </button>
        <button
          mat-button
          color="primary"
          [disabled]="messageForm.invalid"
          (click)="onSaveBroadcastMessage()"
          *ngIf="!isReadOnly"
        >
          Save
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="sendInformation">
      <div class="content-right">
        <button mat-button (click)="backFormScreen()">No</button>
        <button mat-button color="primary" (click)="performOperation()">Yes</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #showCancel>
  <button mat-button (click)="close()" *ngIf="!isReadOnly">Cancel</button>
</ng-template>
