import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Item680CalculatorComponent } from './item680calculator.component';
import { PalletDimensionsComponent } from './pallet-dimensions/pallet-dimensions.component';
import { CommodityDimensionsComponent } from './commodity-dimensions/commodity-dimensions.component';
import { CalculationsComponent } from './calculations/calculations.component';
import { CommentsComponent } from './comments/comments.component';
import { OverallDimensionsComponent } from './overall-dimensions/overall-dimensions.component';
import { InputNumberModule } from '@deividfortuna/input-number';
import { InputValidationModule } from '../../../input-validation/input-validation.module';
import { MaterialModule } from '../../../material.module';
import { Item680CalculatorRoutingModule } from './item680calculator-routing.module';

@NgModule({
  declarations: [
    Item680CalculatorComponent,
    OverallDimensionsComponent,
    PalletDimensionsComponent,
    CommodityDimensionsComponent,
    CalculationsComponent,
    CommentsComponent
  ],
  imports: [Item680CalculatorRoutingModule, CommonModule, MaterialModule, InputNumberModule, InputValidationModule],
  exports: [
    Item680CalculatorComponent,
    OverallDimensionsComponent,
    PalletDimensionsComponent,
    CommodityDimensionsComponent,
    CalculationsComponent,
    CommentsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Item680CalculatorModule {}
