import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ListCustomerGuidelinesComponent } from '../list-customer-guidelines/list-customer-guidelines.component';

@Component({
  selector: 'ig-customer-guidelines-cell-renderer',
  templateUrl: './customer-guidelines-cell-renderer.component.html',
  styleUrls: ['./customer-guidelines-cell-renderer.component.scss'],
  host: { class: 'ig-guidelinesCellRenderer' },
  encapsulation: ViewEncapsulation.None
})
export class CustomerGuidelinesCellRendererComponent implements ICellRendererAngularComp {
  public originalText: string = '';
  public cellTextContent: string = '';
  public isShowMoreLinkDisplayed: boolean = false;
  private readonly MAX_LINES_DISPLAYED: number = 5;
  private readonly MAX_LINE_LENGTH: number = 90;
  private parentComponent: ListCustomerGuidelinesComponent;
  private rowIndex: number;

  constructor() {}

  public agInit(params: ICellRendererParams): void {
    this.parentComponent = params.context;
    this.rowIndex = params.rowIndex;
    this.originalText = params.value || '';
    this.buildDisplayText();
  }

  /**
   * On the grid, we only show guideline up to MAX_LINES_DISPLAYED number, not the character count.
   * Business want to see first MAX_LINES_DISPLAYED lines o the rule, so we're not displaying one item rule per line.
   * To find when to display the 'show more' option, we're removing string for each line and see if the text is more than MAX_LINE.
   */
  private buildDisplayText(): void {
    let restString: string = this.originalText;

    for (let i = 0; i < this.MAX_LINES_DISPLAYED; i++) {
      restString = this.stripALineOfStringFromText(restString);

      // text is less than maximum, we can display all
      if (!restString) {
        this.isShowMoreLinkDisplayed = false;
        this.cellTextContent = this.originalText;
        break;
      }

      // text is more than maximum line, display show more
      if (i === this.MAX_LINES_DISPLAYED - 1 && restString) {
        this.isShowMoreLinkDisplayed = true;
        this.cellTextContent = this.originalText.replace(restString, '');
        if (this.cellTextContent.substring(this.cellTextContent.length - 1, this.cellTextContent.length) === '\n') {
          this.cellTextContent = this.cellTextContent.substring(0, this.cellTextContent.length - 1);
        }
        this.cellTextContent = `${this.cellTextContent}...\n`;
      }
    }
  }

  private stripALineOfStringFromText(text: string): string {
    const removedStringByRange: string = text?.substring(0, this.MAX_LINE_LENGTH); // take string up to MAX_EACH_LENGTH
    const stringForLine: string = removedStringByRange?.split('\n')[0]; // if removedStringByRange contains new lines, take before the new line
    let restString: string = text?.replace(stringForLine, ''); // remove stringForLine from the text
    if (restString?.substring(0, 1) === '\n') {
      restString = restString.substring(1);
    }

    return restString;
  }

  public onShowMoreClicked(): void {
    this.parentComponent.onShowMoreClickedFromGuidelineCell(this.rowIndex);
  }

  public refresh(): boolean {
    return false;
  }

  // Saving below approach in case business want to see one item rule per line.
  // private buildDisplayText(): void {
  //   // const eachRuleArray: string[] = this.fullText.split('\n');

  //   // if (eachRuleArray.length > this.MAX_LINES_DISPLAYED) {
  //   //   // more than 5 rules, display show more
  //   //   this.isShowMoreLinkDisplayed = true;
  //   //   for (let i = 0; i < this.MAX_LINES_DISPLAYED; i++) {
  //   //     if (i !== this.MAX_LINES_DISPLAYED - 1) {
  //   //       this.displayText = this.displayText.concat(`${eachRuleArray[i]}\n`);
  //   //     } else {
  //   //       this.displayText = this.displayText.concat(`${eachRuleArray[i]}...\n`);
  //   //     }
  //   //   }
  //   // } else if (eachRuleArray.length <= this.MAX_LINES_DISPLAYED) {
  //   //   // less than 5 rules
  //   //   for (let i = 0; i < eachRuleArray.length; i++) {
  //   //     this.displayText = this.displayText.concat(`${eachRuleArray[i]}\n`);
  //   //     if (eachRuleArray[i]?.length > this.MAX_LINE_LENGTH) {
  //   //       // less than 5 rules, but more than MAX_LINE_LENGTH
  //   //       this.isShowMoreLinkDisplayed = true;
  //   //       for (let j = i + 1; j < eachRuleArray.length; j++) {
  //   //         this.displayText = this.displayText.concat(`${eachRuleArray[j]}\n`);
  //   //       }
  //   //       break;
  //   //     }
  //   //   }
  //   // } else {
  //   //   // less than maximum, display all
  //   //   this.isShowMoreLinkDisplayed = false;
  //   //   this.displayText = this.fullText;
  //   // }
  // }
}
