import { Injectable } from '@angular/core';
import { ProNumber } from '../../../../classes/pronumber';
import { Dimension3D } from '../../../../classes/dimension3d';
import { PieceDimensions } from '@xpo-ltl/sdk-inspections';
import { Dimensions } from '@xpo-ltl/sdk-common';
import { Dimension2D } from '../../../../classes/dimension2d';

@Injectable({
  providedIn: 'root'
})
export class Item680AppStorageService {
  private readonly ITEM680_NOTE2B_DATA = '680Note2bData';

  constructor() {}

  private getKey(proNumber: ProNumber): string {
    let key;

    if (proNumber) {
      key = `${this.ITEM680_NOTE2B_DATA}-${proNumber.formatProNumber()}`;
    } else {
      key = this.ITEM680_NOTE2B_DATA;
    }

    return key;
  }

  public clearItem6802bData(proNumber: ProNumber) {
    const key = this.getKey(proNumber);
    sessionStorage.removeItem(key);
  }

  public saveItem6802bData(item6802bData: any, pro: string = null) {
    let key;

    if (pro) {
      key = this.getKey(new ProNumber(pro));
    } else {
      key = this.getKey(null);
    }

    // We map Dimension3D to PieceDimensions
    const mappedData = this.mapData(item6802bData);

    sessionStorage.setItem(key, JSON.stringify(mappedData));
  }

  public getItem6802bData(proNumber: ProNumber = null): any {
    let data;
    const key = this.getKey(proNumber);
    const json = sessionStorage.getItem(key);

    if (json) {
      data = JSON.parse(json);
    }

    return data;
  }

  private mapData(data: any): any {
    const mappedData = {
      weight: data.weight,
      dimensions: null,
      pallets: null,
      commodities: null
    };

    if (data.dimensions) {
      mappedData.dimensions = this.mapDimension3DToPieceDimensions(data.dimensions);
    }

    if (data.pallets) {
      mappedData.pallets = data.pallets.filter((pallet: Dimension2D) => {
        return pallet.getArea();
      });
    }

    if (data.commodities) {
      mappedData.commodities = data.commodities.filter((pallet: Dimension2D) => {
        return pallet.getArea();
      });
    }

    return mappedData;
  }

  private mapDimension3DToPieceDimensions(item6802bDimensions: Array<Dimension3D>): Array<PieceDimensions> {
    const dimensions = item6802bDimensions
      .filter((dimension: Dimension3D) => {
        return dimension.getVolume();
      })
      .map((dimension3D: Dimension3D) => {
        const pieceDimensions = new PieceDimensions();
        const dimensionsObject = new Dimensions();

        dimensionsObject.height = dimension3D.getHeight();
        dimensionsObject.length = dimension3D.getLength();
        dimensionsObject.width = dimension3D.getWidth();

        pieceDimensions.dimensions = dimensionsObject;
        pieceDimensions.pieceCnt = dimension3D.getPieces();
        return pieceDimensions;
      });

    return dimensions;
  }

  // Load dimensions for Inspection screen
  public getItem6802bDimensions(proNumber: ProNumber): Array<PieceDimensions> {
    let data;
    let dimensions;
    const key = this.getKey(proNumber);
    const json = sessionStorage.getItem(key);

    if (json) {
      data = JSON.parse(json);
    }

    if (data && data.dimensions) {
      dimensions = data.dimensions;
    }

    return dimensions;
  }
}
