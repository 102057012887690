import { Pipe, PipeTransform } from '@angular/core';
import { DialogAction } from '../enums/dialog-actions.enum';

@Pipe({
  name: 'dialogActionPipe'
})
export class DialogActionPipe implements PipeTransform {
  transform(action: string): string {
    switch (action) {
      case DialogAction.EDIT: {
        return DialogAction.EDIT.toLowerCase();
      }
      case DialogAction.DELETE: {
        return DialogAction.DELETE.toLowerCase();
      }
      case DialogAction.CREATE: {
        return 'add';
      }
      case DialogAction.BROADCAST: {
        return 'send';
      }
    }
  }
}
