<div class="info">
  <div *ngIf="isValidAcoount() && getFormattedParty() && getRecommendation()">
    <strong> {{ getRecommendation() }} </strong> recommend when the
    <strong> {{ getFormattedParty() }} account </strong> is {{ getAccountName() }} ({{ getAccount() }})
    <span *ngFor="let filter of getFilters()">
      <strong> AND </strong> the <strong>{{ filter.getDisplayName().toLowerCase() }}</strong> is
      {{ filterToString(filter) }}
    </span>
  </div>
</div>
