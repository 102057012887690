<div *ngFor="let indicator of indicators" class="pickup-request-mark-cell-renderer__marks">
  <xpo-icon
    *ngIf="indicator.name === indicatorName.GUARANTEED; else notGuaranteedIndicators"
    iconName="{{ indicator.iconName }}"
    class="pickup-request-mark-cell-renderer__marks-guaranteed"
    matTooltip="{{ indicator.name }}"
  ></xpo-icon>

  <ng-template #notGuaranteedIndicators>
    <xpo-icon iconName="{{ indicator.iconName }}" matTooltip="{{ indicator.name }}"></xpo-icon>
  </ng-template>
</div>
