<div class="calculate">
  <div class="button-container">
    <div class="button-title button-title-left">
      <mat-icon id="back-button-icon" svgIcon="back-button-icon" (click)="navigateBack()"></mat-icon>
      Item 680 Note 2b Calculator
    </div>
    <div class="button-title button-title-right">
      <button mat-button (click)="submitForm()">Calculate</button>
      <button mat-button (click)="clearForm()">Clear Form</button>
    </div>
  </div>

  <div id="content-calc">
    <mat-dialog-content style="margin-top: 7px"> </mat-dialog-content>

    <div class="item680-grid-layout">
      <div id="input-form-div">
        <div>
          <app-overall-dimensions [events]="eventsSubject.asObservable()"></app-overall-dimensions>
        </div>
        <div>
          <app-pallet-dimensions [events]="eventsSubject.asObservable()"></app-pallet-dimensions>
        </div>
        <div>
          <app-commodity-dimensions [events]="eventsSubject.asObservable()"></app-commodity-dimensions>
        </div>
      </div>
      <div>
        <app-calculations></app-calculations>
      </div>
    </div>

    <app-comments [events]="eventsSubject.asObservable()"></app-comments>
  </div>
</div>
