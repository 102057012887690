import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(private dialogRef: MatDialogRef<NotesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = '680 Note 2b Notes';
    this.message =
      'Currently there is a note entered in the Inspection, Do you want the 680 Note 2b notes to append to or replace the existing Inspection Note?';
  }

  ngOnInit() {}

  public sendAction(a: string): void {
    this.dialogRef.close(a);
  }

  public close(): void {
    this.dialogRef.close(undefined);
  }
}
