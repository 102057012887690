import { ToolbarComponent } from '../toolbar/toolbar.component';
import { MaterialModule } from '../../material.module';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [MaterialModule],
    declarations: [ToolbarComponent],
    exports: [ToolbarComponent]
})
export class ToolbarModule {}
