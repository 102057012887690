import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PhotoGalleryComponent } from './photo-gallery.component';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { MaterialModule } from '../../material.module';

const components = [PhotoGalleryComponent];

@NgModule({
    declarations: components,
    imports: [MaterialModule, XpoPipesModule],
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PhotoGalleryModule {}
