import { Component, OnInit } from '@angular/core';
import { Item680ValidationsService } from '../services/item680-validations.service';

@Component({
  selector: 'app-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.scss']
})
export class CalculationsComponent implements OnInit {
  constructor(public item680Validations: Item680ValidationsService) {}

  ngOnInit() {}

  public getPalletSurfaceArea(): string {
    let formattedPalletSurfaceArea = '';
    const palletSurfaceArea = this.item680Validations.getPalletSurfacesAreaInSquareFeet();
    if (palletSurfaceArea) {
      formattedPalletSurfaceArea = palletSurfaceArea.toFixed(2);
    }
    return formattedPalletSurfaceArea;
  }

  public getCommoditySurfaceArea(): string {
    let formattedCommoditySurfaceArea = '';
    const commoditySurfaceArea = this.item680Validations.getCommoditySurfacesAreaInSquareFeet();
    if (commoditySurfaceArea) {
      formattedCommoditySurfaceArea = commoditySurfaceArea.toFixed(2);
    }
    return formattedCommoditySurfaceArea;
  }

  public getPercentageOfAreaUsed(): string {
    let formattedPercentageOfAreaUsed = '';
    const percentageOfAreaUsed = this.item680Validations.getPercentageOfSurfaceAreaUsed();
    if (percentageOfAreaUsed) {
      formattedPercentageOfAreaUsed = percentageOfAreaUsed.toFixed(2) + '%';
    }
    return formattedPercentageOfAreaUsed;
  }

  public getShipmentVolume(): string {
    let formattedShipmentVolume = '';
    const shipmentVolume = this.item680Validations.getShipmentVolume();

    if (shipmentVolume) {
      formattedShipmentVolume = shipmentVolume.toFixed(2);
    }

    return formattedShipmentVolume;
  }

  public getShipmentDensity(): string {
    let formattedShipmentDensity = '';
    const shipmentDensity = this.item680Validations.getShipmentDensity();
    if (shipmentDensity) {
      formattedShipmentDensity = shipmentDensity.toFixed(3);
      formattedShipmentDensity = this.truncate(formattedShipmentDensity);
    }
    return formattedShipmentDensity;
  }

  public truncate(value: string): string {
    const indexB = value.indexOf('.');
    const dec = value.substring(indexB + 1, value.length - 1);
    const ent = value.substring(0, indexB);
    return `${ent}.${dec}`;
  }
}
