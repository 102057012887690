<div class="dimension-line">
  <div class="dimension-line__line">
    <mat-form-field class="dimension-line__line-form-field">
      <input
        matInput
        placeholder="Pieces"
        maxlength="4"
        [(ngModel)]="inspectionPieceDimension.pieceCnt"
        (change)="dimensionsChanged()"
        inputmode="numeric"
        pattern="[0-9\-]*"
      />
    </mat-form-field>
    @
    <mat-form-field class="dimension-line__line-form-field">
      <input
        matInput
        placeholder="Length"
        maxlength="5"
        [(ngModel)]="inspectionPieceDimension.dimensions.length"
        (change)="dimensionsChanged()"
        inputmode="numeric"
      />
    </mat-form-field>
    X
    <mat-form-field class="dimension-line__line-form-field">
      <input
        matInput
        placeholder="Width"
        maxlength="5"
        [(ngModel)]="inspectionPieceDimension.dimensions.width"
        (change)="dimensionsChanged()"
        inputmode="numeric"
      />
    </mat-form-field>
    X
    <mat-form-field class="dimension-line__line-form-field">
      <input
        matInput
        placeholder="Height"
        maxlength="5"
        [(ngModel)]="inspectionPieceDimension.dimensions.height"
        (change)="dimensionsChanged()"
        inputmode="numeric"
      />
    </mat-form-field>
  </div>
  <div *ngIf="dimensionError" class="mat-error">{{ dimensionError }}</div>
</div>
