import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { IFrameDisplayData } from '../../classes/iframe-display-data';
import { InjectedDialogComponent } from '../../interfaces/injected-dialog-component.interface';

@Component({
  selector: 'app-iframe-display',
  templateUrl: './iframe-display.component.html',
  styleUrls: ['./iframe-display.component.scss']
})
export class IFrameDisplayComponent implements InjectedDialogComponent {
  public dataObservable: Observable<any>;

  public iFrameContentSrc: SafeUrl;
  public iFrameErrorMessage: string;
  public title: string;

  constructor(
    private dialogRef: MatDialogRef<IFrameDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IFrameDisplayData,
    private sanitizer: DomSanitizer
  ) {
    this.title = data.title;
    this.iFrameContentSrc = sanitizer.bypassSecurityTrustResourceUrl(data.url);
    this.iFrameErrorMessage = data.errorMessage;
  }
}
