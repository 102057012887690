<div class="measurements">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Measurements </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="48">
        <div class="data">Inspector Dimensions</div>
        <div class="label">by Jane Jones at NMP on June 23 at 22:08</div>
        <app-dimensions></app-dimensions>
      </div>
      <div class="horizontal-divider" style="width: 2%;"></div>
      <div fxLayout="column" fxFlex="48">
        <div fxLayout="row">
          <div fxLayout="column" class="measurements__cubic-feet">
            <div class="label">Cubic Feet</div>
            <div class="value">???</div>
          </div>
          <div fxLayout="column" class="measurements__density">
            <div class="label">Density (PCF)</div>
            <div class="value">
              {{ shipmentDetails?.shipmentDetails?.totDensity }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
