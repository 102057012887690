export enum IndicatorName {
  ACCURACY = 'Accuracy',
  FREEZABLE = 'Freezable',
  FOOD = 'Food',
  GUARANTEED = 'Guaranteed',
  HAZMAT = 'Hazmat',
  HEADLOAD = 'Headload',
  POISON = 'Poison',
  UNKNOWN = 'Unknown',
  RAPID_REMOTE_SERVICE = 'Rapid Remote Service'
}
