import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Dimension2D } from '../../../../classes/dimension2d';
import { Item680ValidationsService } from '../services/item680-validations.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-commodity-dimensions',
  templateUrl: './commodity-dimensions.component.html',
  styleUrls: ['./commodity-dimensions.component.scss']
})
export class CommodityDimensionsComponent implements OnInit, OnDestroy {
  private eventsSubscription: any;

  @Input() events: Observable<string>;

  public commodityErrors: Array<string> = new Array<string>();

  constructor(public item680Validations: Item680ValidationsService) {}

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe($event => {
      if ($event === 'submit') {
        this.item680Validations.getCommoditySurfaces().forEach((item, index) => {
          this.validateCommodityDimensions(item, index);
        });
      } else if ($event === 'reset') {
        this.item680Validations.getCommoditySurfaces().push(new Dimension2D());
        this.commodityErrors = new Array<string>();
      }
    });

    if (!this.item680Validations.getCommoditySurfaces().length) {
      this.item680Validations.getCommoditySurfaces().push(new Dimension2D());
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  public addSurface(): void {
    const commoditySurfaces = this.item680Validations.getCommoditySurfaces();
    const commoditySurface = commoditySurfaces[commoditySurfaces.length - 1];
    if (commoditySurface.getWidth() > 0 || commoditySurface.getLength() > 0 || commoditySurface.getPieces() > 0) {
      commoditySurfaces.push(new Dimension2D());
    }
  }

  public handleCommodityChange(): void {
    this.addSurface();
    this.item680Validations.resetComments();
    this.item680Validations.calculateShipmentProperties();
  }

  public validateCommodityDimensions(commoditySurface: Dimension2D, index: number): void {
    let commodityError = '';

    // Commodity dimensions
    const length = +commoditySurface.getLength();
    const width = +commoditySurface.getWidth();
    const area = +commoditySurface.getArea();

    // Largest pallet dimensions
    const palletSurfaceWidth = +this.item680Validations.getWidestPalletDimension();
    const palletSurfaceLength = +this.item680Validations.getLargestPalletDimension();
    const largestPalletSurfaceArea = +this.item680Validations.getLargestPalletArea();

    // Check dimension input
    if (!this.item680Validations.isValidDimension(length) || !this.item680Validations.isValidDimension(width)) {
      commodityError = 'All dimensions must be > 0 and in .5 increments.';
    } else if (length > palletSurfaceLength) {
      commodityError =
        'Length for any entry in Commodity Dimensions cannot exceed the largest length in the Pallet Dimensions.';
    } else if (width > palletSurfaceWidth) {
      commodityError =
        'Width for any entry in Commodity Dimensions cannot exceed the largest width in the Pallet Dimensions.';
    } else if (area > largestPalletSurfaceArea) {
      commodityError =
        'The total square feet for any Commodity Dimensions entry cannot exceed the largest total square feet in the Pallet Dimensions.';
    }

    // Don't add the error if row is empty
    if (length || width || commoditySurface.getPieces()) {
      this.commodityErrors[index] = commodityError;

      // If there is an error, reset calculations
      if (commodityError) {
        this.item680Validations.resetComments();
      }
    } else {
      this.commodityErrors[index] = '';
    }
  }
}
