import { AcctId, AuditInfo } from '@xpo-ltl/sdk-common';
import { CustomerRecommendedRule as CustomerRcmndRule } from '@xpo-ltl/sdk-inspections';
import { PartyRole } from '../components/recommendation-rules/enums/party_role.enum';

export class CustomerRecommendedRule {
  ruleInstId: number;
  typeCd: string;
  inspectionChargeCd: string;
  inspectionSicCd: string;
  ruleRemarks: string;
  auditInfo: AuditInfo;
  customerAccounts: AcctId[];
  consignee: string;
  consigneeMadCd: string;
  acctName: string;
  acctMadCd: string;
  partyRole: string;
  updatedDate: string;
  lastUpdatedId: string;
  createdId: string;
  createdDate: string;
  deletedBy: string;

  map(rule: CustomerRcmndRule): void {
    let indexOfMainAccount = rule?.customerAccounts?.length - 1;
    if (rule?.customerAccounts?.length > 1) {
      indexOfMainAccount = rule?.customerAccounts?.findIndex(
        acct => acct?.acctPartyRole?.toLowerCase() !== PartyRole.Consignee.toLowerCase()
      );
      const indexOfConsigneeAccount = rule?.customerAccounts?.findIndex(
        acct => acct?.acctPartyRole?.toLowerCase() === PartyRole.Consignee.toLowerCase()
      );

      this.populateAccount(rule, indexOfMainAccount);
      if (indexOfConsigneeAccount > -1) {
        this.consignee = rule?.customerAccounts?.[indexOfConsigneeAccount]?.acctName;
        this.consigneeMadCd = rule?.customerAccounts?.[indexOfConsigneeAccount]?.acctMadCd;
      }
    } else {
      this.populateAccount(rule, indexOfMainAccount);
    }

    this.updatedDate = rule?.auditInfo?.updatedTimestamp?.toString();
    this.createdDate = rule?.auditInfo?.createdTimestamp?.toString();
    this.createdId = rule?.auditInfo?.createdById;
    this.lastUpdatedId = rule?.auditInfo?.updateById;
    this.typeCd = rule?.typeCd;
    this.inspectionChargeCd = rule?.inspectionChargeCd;
    this.customerAccounts = rule?.customerAccounts;
    this.ruleInstId = rule?.ruleInstId;
    this.auditInfo = rule?.auditInfo;
    this.inspectionSicCd = rule?.inspectionSicCd;
    this.ruleRemarks = rule?.ruleRemarks;
  }

  private populateAccount(rule: CustomerRcmndRule, indexOfMainAccount: number): void {
    this.acctName = rule?.customerAccounts?.[indexOfMainAccount]?.acctName;
    this.acctMadCd = rule?.customerAccounts?.[indexOfMainAccount]?.acctMadCd;
    this.partyRole = rule?.customerAccounts?.[indexOfMainAccount]?.acctPartyRole;
  }
}
