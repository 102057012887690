export class Dimension2D {
  protected length: number;
  protected width: number;
  protected pieces: number;

  public getLength(): number {
    return this.length;
  }

  public getWidth(): number {
    return this.width;
  }

  public getPieces(): number {
    return this.pieces;
  }

  public setLength(length: number): void {
    this.length = length;
  }

  public setWidth(width: number): void {
    this.width = width;
  }

  public setPieces(pieces: number): void {
    this.pieces = pieces;
  }

  public getArea(): number {
    let area = 0;
    if (this.pieces && this.length && this.width) {
      area = this.pieces * this.length * this.width;
    }
    return area;
  }

  public getAreaFromInchesToFeet(): number {
    // 144 is the convertion factor to cubic feet from inches
    return this.getArea() / 144;
  }

  public getFormattedSurface(): string {
    return `${this.pieces}@${this.length}x${this.width}`;
  }

  // Check if this is being used
  public isWiderThan(surface: Dimension2D): boolean {
    let widerThan = false;

    if ((this.width * this.pieces) > surface.getWidth()) {
      widerThan = true;
    }

    return widerThan;
  }

  public isMoreLengthThan(surface: Dimension2D): boolean {
    let moreLengthThan = false;

    if ((this.length * this.pieces) > surface.getLength()) {
      moreLengthThan = true;
    }

    return moreLengthThan;
  }
}
