<mat-card class="mat-elevation-z">
  <mat-card-header>
    <mat-card-title>Calculations</mat-card-title>
  </mat-card-header>
  <mat-card-content id="content-calculations">
    <div>
      <h4>Pallet Surface Area</h4>
      <div class="result-container">
        {{ getPalletSurfaceArea() }}
        <span *ngIf="getPalletSurfaceArea()">Square Feet</span>
      </div>
    </div>
    <div>
      <h4>Shipment Volume</h4>
      <div class="result-container">
        {{ getShipmentVolume() }}
        <span *ngIf="getShipmentVolume()">Cubic Feet</span>
      </div>
    </div>
    <div>
      <h4>Commodity Surface Area</h4>
      <div class="result-container">
        {{ getCommoditySurfaceArea() }}
        <span *ngIf="getCommoditySurfaceArea()">Square Feet</span>
      </div>
    </div>
    <div>
      <h4>Shipment Density</h4>
      <div class="result-container">
        {{ getShipmentDensity() }}
        <span *ngIf="getShipmentDensity()">PCF</span>
      </div>
    </div>
    <div>
      <h4>Surface Area Used</h4>
      <div class="result-container">
        {{ getPercentageOfAreaUsed() }}
      </div>
    </div>
    <div class="nmfc-class">
      <div>
        <h4>NMFC + Sub</h4>
        <div class="result-container">
          {{ this.item680Validations.getNMFCItem() }}
        </div>
      </div>
      <div>
        <h4>Class</h4>
        <div class="result-container">
          {{ this.item680Validations.getNMFCClass() === 0 ? '' : this.item680Validations.getNMFCClass() }}
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions> </mat-card-actions>
</mat-card>
