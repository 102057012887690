<h2 mat-dialog-title>{{ title }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
  <mat-form-field floatLabel="always" hideRequiredMarker="true">
    <mat-label>Zoom</mat-label>
    <mat-select [(ngModel)]="zoomLevel" required>
      <mat-option *ngFor="let zoomLevel of zoomLevelList" value="{{ zoomLevel }}">{{
        zoomLevel | titlecase
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" (click)="submit()">Ok</button>
  <button mat-button (click)="close()">Cancel</button>
</mat-dialog-actions>
