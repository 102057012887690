import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberFormat]'
})
export class NumberFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur') onblur() {
    this.checkInputValue();
  }

  @HostListener('keypress', ['$event']) onkeypress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.checkInputValue();
    }
  }

  private checkInputValue() {
    let value = this.el.nativeElement.value;

    if (value.length > 1 && value[0] === '.') {
      value = parseFloat('0' + value);
    }

    if (value.length && value.indexOf('.') === value.length - 1) {
      value = value.substring(0, value.length - 1);
    }

    if (!parseFloat(value)) {
      value = '';
    }

    this.el.nativeElement.value = value;
  }
}
