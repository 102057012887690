import { Injectable } from '@angular/core';
import { AppConfigManagerService } from '../app-config-manager.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  isProduction: boolean;
  teamEmail: string;
  emailSubject: string;
  userFullName: string;
  userEmail: string;

  constructor(private configManagerService: AppConfigManagerService, private userService: UserService) {
    this.isProduction = this.configManagerService.isProduction();
    this.teamEmail = this.configManagerService.getTeamEmail();
    this.emailSubject = this.configManagerService.getEmailSubject();
  }

  openEmailWindow(): void {
    let href: string;
    this.userFullName = this.userService.currentUserFullName;
    this.userEmail = this.userService.currentUserEmailAddress;

    if (this.isProduction) {
      // send email to the W&I team for PROD
      href = this.buildBaseHref(this.teamEmail);
    } else {
      // send email only to the person who is testing
      href = this.buildBaseHref(this.userEmail);
    }
    window.location.href = href;
  }

  private buildBaseHref(email: string): string {
    return `mailto:${email}?subject=${this.emailSubject} - ${this.userFullName}`;
  }
}
