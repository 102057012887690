import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementsComponent {
  @Input()
  public shipmentDetails: GetInspectionShipmentDetailsResp;

  constructor() {}
}
