import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WebCameraComponent } from '../../components/web-camera/web-camera.component';

import { CameraComponent } from './../../components/camera/camera.component';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss']
})
export class CameraDialogComponent {
  @ViewChild(CameraComponent, { static: true })
  public camera: CameraComponent;

  constructor(public dialogRef: MatDialogRef<CameraDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
