export class RuleFilter {
  private name: string;
  private displayName: string;
  private type: string;
  private options: Array<string>;
  private value: any;
  private status: string;
  private isValid: boolean;

  constructor(
    displayName: string = '',
    name: string = '',
    type: string = '',
    options: Array<string> = null,
    status: string = 'available',
    isValid: boolean = false
  ) {
    this.displayName = displayName;
    this.name = name;
    this.type = type;
    this.options = options;
    this.status = status;
    this.isValid = isValid;
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string) {
    this.name = value;
  }

  public getDisplayName(): string {
    return this.displayName;
  }

  public setDisplayName(value: string) {
    this.displayName = value;
  }

  public getType(): string {
    return this.type;
  }

  public setType(value: string) {
    this.type = value;
  }

  public getValue(): any {
    return this.value;
  }

  public setValue(value: any) {
    this.value = value;
  }

  public getOptions(): Array<string> {
    return this.options;
  }

  public setOptions(value: Array<string>) {
    this.options = value;
  }

  public getStatus(): string {
    return this.status;
  }

  public setStatus(value: string) {
    this.status = value;
  }

  public getIsValid(): boolean {
    return this.isValid;
  }

  public setIsValid(value: boolean) {
    this.isValid = value;
  }
}
