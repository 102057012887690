export class ComparatorUtils {
  public static stringCompare(string1: string, string2: string): number {
    string1 = string1?.trim();
    string2 = string2?.trim();

    if (string1 > string2) {
      return 1;
    } else if (string1 < string2) {
      return -1;
    } else {
      // if one or both strings are undefined or null, falls in this last else statement.
      return 0;
    }
  }

  public static stringNumberCompare(stringNum1: string, stringNum2: string) {
    let compareNum1 = 0;
    let compareNum2 = 0;

    if (!isNaN(+stringNum1)) {
      compareNum1 = +stringNum1;
    }
    if (!isNaN(+stringNum2)) {
      compareNum2 = +stringNum2;
    }

    return compareNum1 - compareNum2;
  }

  /**
   * If MAD code is a number string, undefined, null, or empty, its invalid/old.
   * Those data will be displayed at the end
   * @param string1 MAD code 1
   * @param string2 MAD code 2
   */
  public static madStringCompare(string1: string, string2: string): number {
    string1 = string1?.trim();
    string2 = string2?.trim();

    // display string number, undefined, null, empty to the last
    if (!isNaN(+string1) || !string1 || string1.length === 0) {
      return 1;
    }
    if (!isNaN(+string2) || !string2 || string2.length === 0) {
      return -1;
    }

    // both strings have value, compare both.
    if (string1 === string2) {
      return 0;
    }
    if (string1 > string2) {
      return 1;
    }
    if (string1 < string2) {
      return -1;
    }
  }

  public static numberCompare(num1: number, num2: number) {
    let compareNum1 = 0;
    let compareNum2 = 0;

    if (!isNaN(num1)) {
      compareNum1 = num1;
    }
    if (!isNaN(num2)) {
      compareNum2 = num2;
    }

    return compareNum1 - compareNum2;
  }

  public static stringAndNumberCompare(string1: string, string2: string): number {
    // update undefined, null, ' ' to ''
    if (!string1 || string1?.trim().length === 0) {
      string1 = '';
    }
    if (!string2 || string2?.trim().length === 0) {
      string2 = '';
    }

    return string1.localeCompare(string2, 'en', { numeric: true });
  }

  /**
   * compare string[] and string[] that contains string and string number
   * example: compare ['50(55-70)', '70(77.5-100)', '100(110-150)'] and ['55(50-125)']
   * @param string1
   * @param string2
   * @returns
   */
  public static arrayOfStringAndNumberCompare(string1: string[], string2: string[]): number {
    const val1: string = ComparatorUtils.buildStringFromStringArray(string1);
    const val2: string = ComparatorUtils.buildStringFromStringArray(string2);
    return val1.localeCompare(val2, 'en', { numeric: true });
  }

  /**
   * build string from string[] and remove empty elements(possible to have empty elements for NMFX, Class, and Commodity columns)
   * example 1: ['50(55-70)', '70(77.5-100)', '100(110-150)'] => '50(55-70),70(77.5-100),100(110-150)'
   * example 2: ['', '', ''] => ''
   * example 3: [undefined, null, '', ' '] => ''
   * example 4: ['', '70(77.5-100)', undefined] => '70(77.5-100)'
   * @param strings
   * @returns
   */
  private static buildStringFromStringArray(strings: string[]): string {
    const flatStringFromStringArray: string = strings
      ?.filter((val) => val?.trim().length > 0)
      ?.toString()
      ?.trim();

    return flatStringFromStringArray?.length > 0 ? flatStringFromStringArray : '';
  }
}
