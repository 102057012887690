import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invertedYesNoPipe'
})
export class InvertedYesNoPipe implements PipeTransform {
  transform(value: any, defaultValue: any): string {
    /* Note: These are reversed because it's an inverted pipe */
    const falseMatches = ['TRUE', 'T', 'YES', 'Y'];
    const trueMatches = ['FALSE', 'F', 'NO', 'N'];

    const valueAsString = `${value}`.toUpperCase();
    if (trueMatches.indexOf(valueAsString) >= 0) {
      return 'Yes';
    }
    if (falseMatches.indexOf(valueAsString) >= 0) {
      return 'No';
    }

    return defaultValue;
  }
}
