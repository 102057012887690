export class RetryStats {
  private static SAMPLE_COUNT = 10;

  public strategyKey: string;
  public times: number[] = [];

  public static loadRetryStats(strategyKey: string): RetryStats {
    const retryStats = new RetryStats();
    retryStats.strategyKey = strategyKey;
    const times = localStorage.getItem('IG-RETRY-STRATEGY:' + strategyKey + '-TIMES');
    if (times) {
      const timesArray = times.split(',');
      timesArray.forEach(value => {
        retryStats.times.push(+value);
      });
    }
    return retryStats;
  }

  public clearRetryStats() {
    localStorage.removeItem('IG-RETRY-STRATEGY:' + this.strategyKey + '-TIMES');
  }

  public saveRetryStats() {
    if (this.times && this.times.length > 0) {
      localStorage.setItem('IG-RETRY-STRATEGY:' + this.strategyKey + '-TIMES', this.times.join(','));
    }
  }

  public addTransactionTime(transactionTime: number) {
    this.times.push(transactionTime);
    while (this.times.length > RetryStats.SAMPLE_COUNT) {
      this.times.shift();
    }
  }

  public evaluateTimeoutValue(defaultTimeout: number) {
    // todo: This could be changed later to modify the default timeout based
    // todo: on the the past statistics.
    // todo: Just reutn the defaultTimeout for now

    return defaultTimeout;
  }
}
