<!-- buttons -->
<div class="buttons-menu-calculators">
  <a> </a>
  <a (click)="showCalculator('item6802B')">
    <mat-icon id="icon-680-item" svgIcon="item-680-icon"></mat-icon>
    <br />
    {{ item680Note2bTitle }}
  </a>
  <a> </a>
  <a> </a>
  <a> </a>
  <a> </a>
</div>
