import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { ProNumber } from '../../classes/pronumber';
import { CameraDialogComponent } from '../../dialogs/camera-dialog/camera-dialog.component';
import { InspectionState } from '../../enums/inspection-state.enum';

@Injectable({ providedIn: 'root' })
export class CameraService {
  private dialogRef: any;

  constructor(private matDialog: MatDialog) {}

  public launchCameraDialog(proNumber: ProNumber, inspectionStatus: InspectionState): Observable<any | undefined> {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxWidth = '100vw';
    matDialogConfig.panelClass = 'fullscreen-dialog';
    matDialogConfig.data = {
      ProNumber: proNumber,
      InspectionStatus: inspectionStatus
    };

    const matDialogRef = this.matDialog.open(CameraDialogComponent, matDialogConfig);

    return matDialogRef.afterClosed();
  }
}
