import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

@Injectable()
export class InputValidators {
  public static validateFormGroupInputs(control: AbstractControl): ValidationErrors | null {
    const formGroup = control as UntypedFormGroup;
    const formControlNames: string[] = Object.keys(formGroup.controls);
    const invalidFormControlNames: string[] = [];
    let hasInvalidInput: boolean;

    formControlNames.forEach((formControl: string) => {
      if (formGroup.get(formControl).invalid) {
        hasInvalidInput = formGroup.get(formControl).invalid;
        invalidFormControlNames.push(formControl);
      }
    });

    if (!hasInvalidInput) {
      return null;
    } else {
      return { hasInvalidInput: true, invalidField: invalidFormControlNames };
    }
  }

  public static validateInput(
    control: AbstractControl,
    isInputHasMatchedOptions: boolean = true
  ): ValidationErrors | null {
    const formControl: UntypedFormControl = control as UntypedFormControl;

    if (formControl.pristine) {
      return null;
    } else {
      const isInputNotEmpty: boolean = formControl.value?.trim().length > 0;

      if (isInputNotEmpty && isInputHasMatchedOptions) {
        return null;
      } else {
        return { isInvalidInput: true };
      }
    }
  }
}
