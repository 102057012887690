import { ShiftCode } from '../enums/shift-code.enum';

export class SicShiftPreference {
  private sicCd: string;
  private shiftCd: ShiftCode;

  public get sic(): string {
    return this.sicCd;
  }

  public set sic(sic: string) {
    this.sicCd = sic;
  }

  public get shift(): ShiftCode {
    return this.shiftCd;
  }

  public set shift(shift: ShiftCode) {
    this.shiftCd = shift;
  }

  public setPreferencesFromJson(jsonPreference: string): void {
    const preference = JSON.parse(jsonPreference);

    for (const property in preference) {
      if (preference.hasOwnProperty(property)) {
        this[property] = preference[property];
      }
    }
  }

  public convertToJson(): string {
    return JSON.stringify(this);
  }
}
