import { Pipe, PipeTransform } from '@angular/core';
import { ShiftCode, ShiftCodeDisplayMap } from '../enums/shift-code.enum';

@Pipe({
  name: 'displayShiftPipe'
})
export class ShiftDisplayEnumStringPipe implements PipeTransform {
  transform(shiftCode: ShiftCode): string {
    return ShiftCodeDisplayMap.get(shiftCode);
  }
}
