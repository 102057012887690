import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'nameAddressPipe'
})
export class NameAddressPipe implements PipeTransform {
  transform(address: Address): string {
    if (address) {
      return `${address.addressLine1}<br/>${address.cityName}, ${
        address.stateCd
      } ${address.postalCd}<br/>`;
    }

    return '';
  }
}
