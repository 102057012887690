import { Pipe, PipeTransform } from '@angular/core';
import { ProNumber } from '../classes/pronumber';

@Pipe({
  name: 'childProPipe'
})
export class ChildProPipe implements PipeTransform {
  transform(childPro: string): string {
    let proNbr: ProNumber;

    if (ProNumber.isValid(childPro)) {
      proNbr = new ProNumber(childPro);
      return proNbr.formatProNumber();
    } else {
      return '';
    }
  }
}
