import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Comment } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

import { ProNumber } from '../../../../classes/pronumber';
import { InspectionLocalStorageService } from '../../../../services/inspection-local-storage.service';
import { Item680ValidationsService } from '../services/item680-validations.service';
import { NotesDialogComponent } from './../../../../dialogs/notes-dialog/notes-dialog.component';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
  public isAtLeastOneSectionEmpty: boolean;
  public isShipmentWeightEmpty: boolean;
  public isFormSubmitted: boolean;
  public item680Comments: string;
  public disabledCopyNoteButton = true;

  private eventsSubscription: any;
  private pro: ProNumber;

  @Input() events: Observable<string>;

  constructor(
    public item680Validations: Item680ValidationsService,
    public inspectionLocalStorage: InspectionLocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.pro = new ProNumber(params.get('pro'));
    });

    this.eventsSubscription = this.events.subscribe($event => {
      if ($event === 'submit') {
        this.setComments();
        this.disabledCopyNoteButton = false;
      } else if ($event === 'reset') {
        this.isAtLeastOneSectionEmpty = false;
        this.isShipmentWeightEmpty = false;
        this.isFormSubmitted = false;
        this.item680Comments = '';
        this.disabledCopyNoteButton = true;
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  public setComments(): void {
    this.isAtLeastOneSectionEmpty = this.item680Validations.almostOneSectionsIsEmpty();
    this.isShipmentWeightEmpty = this.item680Validations.shipmentWeightIsZero();
    this.isFormSubmitted = this.item680Validations.getItem680CalculatorSubmitted();
    this.item680Comments = this.item680Validations.getItem680Comments();
  }

  public copyComments(): void {
    const container = document.getElementById('item680Comments');
    const text = container.innerText;

    // We create an invisible textarea containing our text
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = text;

    // We add it to the body
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    // We copy the text
    document.execCommand('copy');

    // Finally we remoce the textarea
    document.body?.removeChild(textarea);
  }

  public copyNoteToInspection(): void {
    const inspectionNote = new Comment();
    inspectionNote.note = document.getElementById('item680Comments').innerText;

    // if note is empty set note, else ask user what to do w/ existing notes
    if (
      !this.inspectionLocalStorage.getInspectionNotes(this.pro) ||
      !this.inspectionLocalStorage.getInspectionNotes(this.pro).note
    ) {
      this.inspectionLocalStorage.setInspectionNotes(this.pro, inspectionNote);
    } else {
      this.confirmationDialog();
    }
  }

  public disableCopyCommentsButton(): boolean {
    let disableButton = false;

    if (!this.item680Comments || !this.item680Validations.getItem680CalculatorSubmitted()) {
      disableButton = true;
    }
    return disableButton;
  }

  public displayButton(): boolean {
    // if 680Note2b is accessed from tools drawer, hide "Copy Note To Inspection" button
    // check URL if finishes with 680Note2b, do not display button.
    return !this.router.url.substring(this.router.url.lastIndexOf('/') + 1).includes('680Note2b');
  }

  public confirmationDialog(): void {
    const dialogRef = this.dialog.open(NotesDialogComponent, {});
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        const inspectionNote = new Comment();
        inspectionNote.note = document.getElementById('item680Comments').innerText;

        switch (resp) {
          case 'append': {
            const notes = this.inspectionLocalStorage.getInspectionNotes(this.pro).note + ' \n\n' + inspectionNote.note;
            inspectionNote.note = notes;
            this.inspectionLocalStorage.setInspectionNotes(this.pro, inspectionNote);
            this.disabledCopyNoteButton = true; // Disables button to avoid mutliple notes copied to inspection.
            break;
          }
          case 'replace': {
            this.inspectionLocalStorage.setInspectionNotes(this.pro, inspectionNote);
            this.disabledCopyNoteButton = true; // Disables button to avoid mutliple notes copied to inspection.
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }
}
