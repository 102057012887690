<div class="confirm-cancel-container">
  <h2 md-dialog-title>{{ title }}</h2>
  <mat-dialog-content class="confirm-cancel-container_content">
    <div [innerHTML]="content"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="confirm-cancel-container_actionbar">
    <button mat-button color="primary" [mat-dialog-close]="false">
      {{ leftButtonTitle }}
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true">
      {{ rightButtonTitle }}
    </button>
  </mat-dialog-actions>
</div>
